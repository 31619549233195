<template>
  <div class="modal-overlay" @click="$emit('close-uploads-modal')">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-upload text-primary me-2" aria-hidden="true"></i> Upload {{ props.uploads.label }}
      </div>
      <hr class="horizontal dark m-0" />

      <el-form
          ref="uploadsFormRef"
          :model="uploadsForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div @click.prevent="downloadSample()" class="d-flex mb-4 align-items-center">
            <argon-button
                color="success"
                variant="outline"
                size="sm"
                class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
            >
              <i class="fas fa-arrow-down" aria-hidden="true"></i>
            </argon-button>
            <div class="d-flex pointer flex-column">
              <h6 class="mb-1 text-dark text-sm">Download Sample</h6>
            </div>
          </div>
          <div class="mb-3">
            <el-upload
                class="upload-demo"
                drag
                accept=".xls,.xlsx"
                action="https://web-api.m-reside.com/api/m-reside/v1/uploads"
                :before-upload="handleBeforeUpload"
                :on-success="handleUploadSuccess"
                :on-error="handleUploadError"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                Drop Excel file here or <em>click to upload</em>
              </div>
              <div class="el-upload__tip">Excel files only</div>
            </el-upload>
          </div>
        </div>

        <div class="card--footer">
          <button
              class="btn mb-0 btn-danger btn-xs null null"
              @click="$emit('close-modal')"
          >Cancel</button>
          <el-button
              type="success"
              :loading="isLoading"
              @click="submitForm(uploadsFormRef)">
              Submit
          </el-button>
        </div>
      </el-form>
    </div>
    <div class="close" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>

import {reactive, ref} from "vue";
import {ElNotification} from "element-plus";
import { uploads } from "@/api/property-manager/uploads";
import { sampleDownload } from "@/api/property-manager/downloads";
import { ElMessage } from "element-plus";
import ArgonButton from "@/components/ArgonButton.vue";

const uploadsFormRef = ref();
const isLoading = ref(false);
export default {
  name: "uploads-form",
  components: {ArgonButton},
  props: {
    uploads: {
      type: Object,
      required: false,
    },
  },


  setup(props, { emit }) {
    const handleBeforeUpload = (file) => {
      const fileType = file.type;
      const validExcelTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ];
      const isValidExcelType = validExcelTypes.includes(fileType);

      if (!isValidExcelType) {
        this.$message.error('Please upload a valid Excel file.');
        return false; 
      }

      return true; 
    }

    const formData = new FormData();

    const handleUploadSuccess = (response, file) => {
      console.log('Upload success:', response, file);
      console.log(file)
      const fileContent = file.raw;

      formData.append("file", fileContent);
    }
    const handleUploadError = (err, file) => {
      console.error('Upload error:', err, file);
    }

    const uploadsForm = reactive({
      file: props?.uploads?.file || 0,
      name: props?.uploads?.name || "",
    });

    const rules = reactive({

      file: [
        {
          required: true,
          message: "Please upload file",
          trigger: ["blur", "change"],
        },
      ],
    });
    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          formData.append("propertyId", props?.uploads?.property_id);
          formData.append("uploadName", props?.uploads?.name);

          uploads(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("refresh-uploads");
                  emit("close-modal");
                } else if(data.error){
                  ElNotification({
                    title: "Error",
                    message: data.error,
                    type: "error",
                  })
                } else {
                  Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  ElNotification({
                    title: "Error",
                    message: err.message,
                    type: "error",
                  })
              )
              .finally(() => {
                isLoading.value = false;
              });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    async function downloadSample(){
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Your download will start shortly. Please wait...",
        });

        await sampleDownload (props.uploads.name, props?.uploads?.property_id)
            .then((Response) => {
              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/vnd.ms-excel",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  `sample ${props.uploads.name}_` + Math.round(+new Date() / 1000) + ".xlsx"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
              emit("close-modal");
            })
            .catch((err) => {
              console.log(err);
              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      }
    }
    return {
      downloadSample,
      submitForm,
      rules,
      uploadsForm,
      uploadsFormRef,
      isLoading,
      props,
      handleUploadSuccess,
      handleUploadError,
      handleBeforeUpload
    };
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 7px;
  width: 550px;
  max-height: 400px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}

.card-body {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}

.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}

.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.pointer {
  cursor: pointer;
}

.close i {
  font-size: 15px;
}
input {
  border-radius: 6px;
}
</style>