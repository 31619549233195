import {api} from "@/api";

export const updatePropertyManager = async(body, id) => {
    let response = await api
        .post(`update-manager/${id}`, body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchManagerDetails = async(id) => {
    let response = await api
        .get("fetch-manager-details/"+ id)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};