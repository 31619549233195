import {api} from "../";

export const fetchClosedLeases = async(filter, page) => {
    var url = "manager-closed-leases?";
    
    if (filter.value != undefined && filter.value.tenant_name)
    url = url + "&tenant_name=" + filter.value.tenant_name;

    if (filter.value != undefined && filter.value.unit_code)
    url = url + "&unit_code=" + filter.value.unit_code;


    if (filter.value != undefined && filter.value.date_range)
     url = url + "&date_range=" + filter.value.date_range;

    if (page) url = url + "?page=" + page;
    url = url.replace("manager-closed-leases", "manager-closed-leases?");
    return await api
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
};

export const fetchSingleClosedLease = async(id) => {
    let response = await api
        .get(`lease-close-details/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};



export const deleteClosedLeases = async(body) => {
    let response = await api
        .post(`delete-closed`, body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}
export const fetchClosedLeasePayments = async(search, page, id) => {
    var url = `closed-lease/payments/${id}`;
    if (search)
        url =
            url +
            "&searchFields=unitCode:like;isLeased:like;tenantId:like;comments:like;&search=" +
            search;
    if (page) url = url + "?page=" + page;
    url = url.replace(`closed-lease/payments/${id}?`, `closed-lease/payments/${id}?`);
    let response = await api
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchClosedLeaseDeposits = async(search, page, id) => {
    var url = `closed-lease/deposits/${id}`;
    if (search)
        url =
            url +
            "&searchFields=unitCode:like;isLeased:like;tenantId:like;comments:like;&search=" +
            search;
    if (page) url = url + "?page=" + page;
    url = url.replace(`closed-lease/deposits/${id}?`, `closed-lease/deposits/${id}?`);
    let response = await api
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchClosedLeaseArrears = async(search, page, id) => {
    var url = `closed-lease/arrears/${id}`;
    if (search)
        url =
            url +
            "&searchFields=unitCode:like;isLeased:like;tenantId:like;comments:like;&search=" +
            search;
    if (page) url = url + "?page=" + page;
    url = url.replace(`closed-lease/arrears/${id}?`, `closed-lease/arrears/${id}?`);
    let response = await api
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchClosedLeaseWaterBills = async(search, page, id) => {
    var url = `closed-lease/water-bills/${id}`;
    if (search)
        url =
            url +
            "&searchFields=unitCode:like;isLeased:like;tenantId:like;comments:like;&search=" +
            search;
    if (page) url = url + "?page=" + page;
    url = url.replace(`closed-lease/water-bills/${id}?`, `closed-lease/water-bills/${id}?`);
    let response = await api
        .get(url)

        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchClosedLeasePenalties = async(search, page, id) => {
    var url = `closed-lease/penalties/${id}`;
    if (search)
        url =
            url +
            "&searchFields=unitCode:like;isLeased:like;tenantId:like;comments:like;&search=" +
            search;
    if (page) url = url + "?page=" + page;
    url = url.replace(`closed-lease/penalties/${id}?`, `closed-lease/penalties/${id}?`);
    let response = await api
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchClosedLeaseUnitCharges = async(search, page, id) => {
    var url = `closed-lease/charges/${id}`;
    if (search)
        url =
            url +
            "&searchFields=unitCode:like;isLeased:like;tenantId:like;comments:like;&search=" +
            search;
    if (page) url = url + "?page=" + page;
    url = url.replace(`closed-lease/charges/${id}?`, `closed-lease/charges/${id}?`);
    let response = await api
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};