import { api } from "../";

const units_url = (root, filter, page, id) => {
    var url = root;

    if (filter.value != undefined && filter.value.search_query)
        url =
            url +
            "&searchFields=unitCode:like;isLeased:like;hoaFee:like;lastUpdated:like;&search=" +
            filter.value.search_query;

    if (page) url = url + "&page=" + page;

    if (filter.value != undefined && filter.value.rental_rate)
        url = url + "&hoaFee=" + filter.value.rental_rate;

    if (filter.value != undefined && filter.value.unit_code)
        url = url + "&unitCode=" + filter.value.unit_code;

    if (filter.value != undefined && filter.value.lease_status)
        url = url + "&isLeased=" + filter.value.lease_status;

    url = url.replace(`property/units/${id}?`, `property/units/${id}?`);

    return url;
};

export const fetchUnits = async() => {
    let response = await api
        .get("units")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchVacantUnits = async() => {
    let response = await api
        .get("vacant/units")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchPropertyUnits = async(filter, page, id) => {
    let response = await api
        .get(units_url(`property/units/${id}?`, filter, page, id))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchPropertyVacantUnits = async(search, page, id) => {
    var url = `property/vacant/units/${id}`;
    if (search)
        url =
            url +
            "&searchFields=unitCode:like;isLeased:like;hoaFee:like;lastUpdated:like;&search=" +
            search;
    if (page) url = url + "?page=" + page;
    url = url.replace(`property/vacant/units/${id}?`, `property/vacant/units/${id}?`);
    let response = await api
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchUnitDetails = async(id) => {
    let response = await api
        .get(`units/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const createUnit = async(postBody) => {
    let response = await api
        .post("units", postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updateUnit = async(body, id) => {
    let response = await api
        .patch("units/" + id, body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const changeUnitStatus = async(id) => {
    let response = await api
        .post(`deactivate/${id}/unit`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const removeUnit = async(id) => {
    let response = await api
        .delete(`units/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const deleteUnits = async(postBody) => {
    let response = await api
        .post("delete-units", postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchUnitPayments = async(search, page, id) => {
    var url = `unit/payments/${id}`;
    if (search)
        url =
            url +
            "&searchFields=unitCode:like;isLeased:like;tenantId:like;comments:like;&search=" +
            search;
    if (page) url = url + "?page=" + page;
    url = url.replace(`unit/payments/${id}?`, `unit/payments/${id}?`);
    let response = await api
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchUnitDeposits = async(search, page, id) => {
    var url = `unit/deposits/${id}`;
    if (search)
        url =
            url +
            "&searchFields=unitCode:like;isLeased:like;tenantId:like;comments:like;&search=" +
            search;
    if (page) url = url + "?page=" + page;
    url = url.replace(`unit/deposits/${id}?`, `unit/deposits/${id}?`);
    let response = await api
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchUnitArrears = async(search, page, id) => {
    var url = `unit/arrears/${id}`;
    if (search)
        url =
            url +
            "&searchFields=unitCode:like;isLeased:like;tenantId:like;comments:like;&search=" +
            search;
    if (page) url = url + "?page=" + page;
    url = url.replace(`unit/arrears/${id}?`, `unit/arrears/${id}?`);
    let response = await api
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchUnitWaterBills = async(search, page, id) => {
    var url = `unit/water-bills/${id}`;
    if (search)
        url =
            url +
            "&searchFields=unitCode:like;isLeased:like;tenantId:like;comments:like;&search=" +
            search;
    if (page) url = url + "?page=" + page;
    url = url.replace(`unit/water-bills/${id}?`, `unit/water-bills/${id}?`);
    let response = await api
        .get(url)

        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchUnitPenalties = async(page, id) => {
    var url = `unit/penalties/${id}`;
    if (page) url = url + "?page=" + page;
    url = url.replace(`unit/penalties/${id}?`, `unit/penalties/${id}?`);
    let response = await api
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchUnitUnitCharges = async(search, page, id) => {
    console
    var url = `unit/charges/${id}`;
    if (page) url = url + "?page=" + page;
    url = url.replace(`unit/charges/${id}?`, `unit/charges/${id}?`);
    let response = await api
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const fetchLeasedUnits = async(id) => {
    let response = await api
        .get("property/leased/units/"+ id)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchLeasedManagerUnits = async() => {
    let response = await api
        .get("manager/leased/units")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};