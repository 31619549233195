<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex align-items-center gap-2"></div>
      <div class="cta">
        <el-button-group>
          <el-button
              type="info"
              @click="submitExcelForm(accountFormRef)"
          >
            <i class="fa fa-stack-exchange" aria-hidden="true"></i>&nbsp; Excel
          </el-button>
          <el-button
              type="warning"
              @click="downloadPdf(accountFormRef)"
          >
            <i class="fa fa-file-text-o" aria-hidden="true"></i>&nbsp; PDF
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="card-body">
      <div v-if="payments.data.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive" v-else>
        <div class="card-table">
          <table class="table align-items-center">
            <thead class="sticky-top">
            <tr>
              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Unit Information
              </th>

              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start"
              >
                Tenant Name
              </th>

              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Amount
              </th>


              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Balance
              </th>

              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Transaction Reference
              </th>
              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Payment Source
              </th>

              <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Payment Date
              </th>

              <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in payments.data" :key="item.payId + index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <div class="avatar avatar-sm me-3 bg-gradient-primary">
                      {{
                        getInitials(
                            item.units?.property?.propertyName
                        ).toUpperCase()
                      }}
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">
                      {{ item.unitCode }}
                    </h6>
                    <p class="text-xs text-secondary mb-0">{{ item.units?.property?.propertyName }}</p>
                  </div>
                </div>
              </td>

              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">
                      {{ item?.units?.leases[0]?.tenant?.firstName  + " " + item?.units?.leases[0]?.tenant?.lastName }}
                    </h6>
                    <p class="text-xs text-secondary mb-0">{{ item?.units?.leases[0]?.tenant?.primaryPhone }}</p>
                  </div>
                </div>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ formatNumber(item.amountPaid) }}</p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ formatNumber(item?.units?.leases[0]?.accounts[0]?.balance) }}</p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item.thirdpartyPaymentRef }}</p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item.source }}</p>
              </td>

              <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
                      dateTime(item.paymentDate)
                    }}</span>
              </td>
              <td class="align-middle text-center">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <i class="fa fa-ellipsis-v text-primary"></i>
                    </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                          class="text-secondary font-weight-bold text-xs"
                          @click="downloadReceipt(item.payId)"
                      ><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                        Download Receipt
                      </el-dropdown-item>
                      <router-link
                          :to="{ path: `/unit/${item.unitId}` }"
                          class="text-secondary"
                      ><el-dropdown-item
                          class="text-secondary font-weight-bold text-xs"
                      ><i class="fa fa-eye" aria-hidden="true"></i>
                        View Tenant</el-dropdown-item
                      ></router-link>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-center mb-3">
        <pagination
            :totalPages="Pagination.total_pages"
            :perPage="Pagination.per_page"
            :currentPage="Pagination.current_page"
            @pagechanged="onPageChange"
        />
      </div>
    </div>
  </div>

  <vue3-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1500"
      filename="receipt.pdf"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-content-width="100%"
      ref="html2Pdf"
  >
    <template v-slot:pdf-content>
      <PrintReceiptForm
          :payment="payment"
      />
    </template>
  </vue3-html2pdf>

</template>

<script>
import EmptyStateTable from "../../components/EmptyTableState.vue";
import moment from "moment";
import {ref, reactive, toRefs, watch} from "vue";
import Pagination from "./Pagination.vue";
import Vue3Html2pdf from "vue3-html2pdf";
import PrintReceiptForm from "../../../components/Modals/property-manager/PrintReceiptForm.vue";
import {ElMessage} from "element-plus";
import {downloadReceiptPdf} from "../../../api/property-manager/downloads";

const filter_params = ref({});
const isLoading = ref(false);

export default {
  name: "payments-table",
  props: {
    payments: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
    propertyId: {
      type: Object,
      required: true,
    },
    filter: {
      type: Boolean,
      required: true,
    }
  },
  components: {
    PrintReceiptForm,
    Pagination,
    EmptyStateTable,
    Vue3Html2pdf
  },

  setup(props) {
    const htmlToPdfOptions = {
      margin: 0.3,
      enableLinks: false,
      image: {
        type: 'jpeg',
        quality: 1,
      },
      html2canvas: {
        scale: 5,
        useCORS: true,
      },

      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait",
      },
    };

    const { payments } = toRefs(props);

    const total_pages = ref(0);
    const per_page = ref(0);
    const updateTotalPages = () => {
      const total_records = payments.value?.total || 0;
      const per_page = payments.value?.per_page || 10;
      if (per_page > 0 && total_records > 0) {
        total_pages.value = Math.ceil(total_records / per_page);
      } else {
        total_pages.value = 0;
      }
    };

    watch(payments, () => {
      updateTotalPages();
      Pagination.current_page = payments.value?.current_page || 1;
    });

    updateTotalPages();

    const Pagination = reactive({
      current_page: payments.value?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });
    const onPageChange = (page) => {
      props.refresh(filter_params, page);
    };
    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY HH:mm:ss");
    };
    const formatNumber = (numberToFormat) => {
      return new Intl.NumberFormat().format(numberToFormat);
    };

    async function downloadReceipt(id){
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Receipt will be downloading shortly. Please wait...",
        });
        isLoading.value = true;

        await downloadReceiptPdf(id)
            .then((Response) => {
              console.log(Response)

              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/pdf",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "tenant_receipt_" + Math.round(+new Date() / 1000) + ".pdf"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);

              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    }

   
    return {
      formatNumber,
      Pagination,
      onPageChange,
      dateTime,
      getInitials,
      htmlToPdfOptions,
      downloadReceipt
    };
  },
 

  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      payment: null,
      userPermissions: storedPermissions || [],
    };
  },

  methods: {
    printInvoicePdf(payment) {
      this.payment = payment;
      this.$refs.html2Pdf.generatePdf();
    },
    hasPermission(slug) {
        return this.userPermissions.includes(slug);
      },
  },
};
</script>

<style scoped>

.cta button {
  margin-right: 10px;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
.card-table {
  max-height: 65vh;
  overflow-x: auto;
  overflow-y: auto;
}
h6 {
  margin: 0;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
</style>
