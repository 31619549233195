<template>
  <div
    v-show="this.$store.state.layout === 'default'"
    class="min-height-300 bg-color position-absolute w-100"
    :class="`${this.$store.state.darkMode ? 'bg-transparent' : 'bg-success'}`"
  />
  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs z-index-1 border-radius-xl"
    :class="`${
      this.$store.state.isRTL
        ? 'me-3 rotate-caret fixed-end'
        : 'fixed-start ms-3'
    } 
    ${
      this.$store.state.layout === 'landing'
        ? 'bg-transparent shadow-none'
        : ' '
    } ${this.$store.state.sidebarType}`"
    id="sidenav-main"
  >
    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>

      <router-link class="m-0 navbar-brand" to="/dashboard-default">
        <img
          :src=" 
            this.$store.state.darkMode ||
            this.$store.state.sidebarType === 'bg-default'
              ? logoWhite 
              : logo
          "
          class="navbar-brand-img h-100 w-55 img-custom"
          alt="main_logo"
        />
        <span class="ms-2 font-weight-bold me-2"></span>
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />

    <div class="author align-items-center p-3">
      <div class="name p-1">
        <span>
          <h6>
            {{ propertyManager?.name }}
          </h6>
        </span>
      </div>
    </div>

    <hr class="mt-0 horizontal dark" />
    <sidenav-list :cardBg="custom_class" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo-ct-dark.png";
import logoWhite from "@/assets/img/logo-ct.png";

// const propertyManager = JSON.parse(localStorage.getItem("propertyManager"));

export default {
  name: "index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoWhite,
      name: "",
      propertyManagerLogo:"",
      propertyManager:"",
      path:"",
      url:""
    };
  },
  created() {
    this.name = localStorage.getItem("profileName");
    this.propertyManager = JSON.parse(localStorage.getItem("propertyManager"));
    this.propertyManagerLogo = localStorage.getItem("managerLogo");
    this.url = process.env.VUE_APP_API_URL
    this.path  = `${process.env.VUE_APP_API_URL}images?path=${this.propertyManagerLogo}`
   console.log(this.path)
    },
    
  methods: {

  },
  props: ["custom_class", "layout"],
 
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 7px;
  width: 450px;
  height: 450px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}

.card-body {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}

.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.bg-success{
  background-color: #5f924d !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

input {
  border-radius: 6px;
}
.img-custom{
  margin-left:20%;
  height:20rem !important;
}

</style>
