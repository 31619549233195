import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import moment from "moment";
import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
// Importing the global css file
import "@/assets/css/global.css";
const appInstance = createApp(App);



appInstance.config.errorHandler = () => null;
appInstance.config.warnHandler = () => null;
appInstance.config.globalProperties.$moment = moment;
appInstance.use(VueTelInput);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(ElementPlus);
appInstance.mount("#app");
