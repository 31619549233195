<template>
  <div v-if="isLoading"><Loader /></div>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0 mb-5">
            <div class="d-flex align-items-center gap-2">
              <i class="fa fa-paper-plane text-primary text-md opacity-10"></i>
              <h6>Send KRA Invoices</h6>
            </div>
          </div>
          <div class="col-md-12">
            <div class="card-body">
              <p class="text-uppercase text-sm">Property Information</p>
              <div class="row">
                <div class="col-md-4 with-vertical-line">
                  <dl>
                    <dt v-if="property?.propertyName">Property Name</dt>
                    <dd v-if="property?.propertyName">{{ capitalized(property?.propertyName) }}</dd>
                    <dt v-if="property?.propertyAddress">Property Location</dt>
                    <dd v-if="property?.propertyAddress">{{ capitalized(property?.propertyAddress) }}</dd>
                  </dl>
                </div>
                <div class="col-md-4  with-vertical-line">
                  <dl>
                    <dt  v-if="property?.landlords?.name">Landlord Name</dt>
                    <dd  v-if="property?.landlords?.name">{{ capitalized(property.landlords.name) }}</dd>
                    <dt v-if="property?.lastUpdated">Created Date</dt>
                    <dd v-if="property?.lastUpdated">{{ dateTime(property.lastUpdated) }}</dd>
                  </dl>
                </div>
                <div class="col-md-3">
                  <dl>
                    <dt v-if="property?.landlord_kra_pin">Landlord Pin</dt>
                    <dd v-if="property?.landlord_kra_pin">{{ capitalized(property.landlord_kra_pin) }}</dd>
                    <dt v-if="property?.kra_rate">KRA Rate</dt>
                    <dd v-if="property?.kra_rate">{{ property?.kra_rate }}</dd>
                  </dl>
                </div>
              </div>
              <hr class="horizontal dark" />
            </div>
          </div>
          <div class="px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <div class="card-body">
                <div v-if="units?.length === 0">
                  <empty-state-table />
                </div>
                <div class="table-responsive" v-else>
                  <div class="card-table">
                    <table class="table align-items-center">
                      <thead class="sticky-top">
                      <tr>
                        <th
                            class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
                        >
                          <input type="checkbox" v-model="selectAll" />
                        </th>
                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                        >
                          Tenant Name
                        </th>
                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                        >
                          Phone Number
                        </th>
                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                        >
                          Tenant Email
                        </th>

                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                        >
                          Unit Code
                        </th>
                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                        >
                          Rent
                        </th>
                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                        >
                          Status
                        </th>
                        <th
                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Action
                        </th>

                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in units" :key="item.unitId + index">
                        <td>
                          <input
                              type="checkbox"
                              v-model="selected"
                              :value="item.unitId"
                              number
                          />
                        </td>
                        <td>
                          <p class="text-xs font-weight-bold mb-0">{{ item?.leases[0]?.tenant.firstName + " " + item?.leases[0]?.tenant.lastName }}</p>
                        </td>

                        <td>
                          <p class="text-xs font-weight-bold mb-0">{{ item?.leases[0]?.tenant.primaryPhone }}</p>
                        </td>

                        <td>
                          <p class="text-xs font-weight-bold mb-0">
                            {{ item?.leases[0]?.tenant.email }}
                          </p>
                        </td>

                        <td>
                          <p class="text-xs font-weight-bold mb-0">
                            {{ item.unitCode }}
                          </p>
                        </td>
                        <td>
                          <p class="text-xs font-weight-bold mb-0">
                            {{ item.hoaFee }}
                          </p>
                        </td>
                        <td>
                          <p class="text-xs font-weight-bold mb-0">
                            {{ item.invoices[0]?.qr_code_url ? 'Sent':'Not Sent' }}
                          </p>
                         
                        </td>
                        <td class="align-middle text-center">
                        <el-dropdown trigger="click">
                            <span class="el-dropdown-link">
                              <i class="fa fa-ellipsis-v text-primary"></i>
                            </span>
                          <template #dropdown>
                            <el-dropdown-menu>
                              <el-dropdown-item
                                 v-if="item.invoices[0]?.qr_code_url"
                                  class="text-secondary font-weight-bold text-xs"
                                  @click="downloadInvoice(item.unitId)"
                              ><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                Download Invoice
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                      </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-4 with-vertical-line">
                      <p class="text-uppercase text-sm text-center">Enter Invoice Due Date</p>
                      <el-form
                          ref="dateFormRef"
                          :model="dateForm"
                          :rules="rules.date"
                          label-width="200px"
                          size="" status-icon
                          :scroll-to-error="true"
                          label-position="top"
                          :hide-required-asterisk="true">
                        <div class="card-body">
                          <div class="col-md-12 mb-3">
                            <el-form-item label="Pick Date And Time" prop="date">
                              <el-date-picker
                                  v-model="dateForm.date"
                                  style="width: 100%"
                                  type="datetime"
                                  placeholder="Select date and time"
                                  :shortcuts="shortcuts"
                              />
                            </el-form-item>
                          </div>
                        </div>
                      </el-form>
                    </div>
                    <div class="col-md-6">
                      <p class="text-uppercase text-sm text-center">Enter Footer Message Here</p>
                      <el-form
                          ref="messageFormRef"
                          :model="messageForm"
                          :rules="rules.message"
                          label-width="200px"
                          size="" status-icon
                          :scroll-to-error="true"
                          label-position="top"
                          :hide-required-asterisk="true">
                        <div class="card-body">
                          <div class="mb-3">
                            <el-form-item label="Message" prop="message">
                              <el-input
                                  v-model="messageForm.message"
                                  placeholder=""
                                  type="textarea" />
                            </el-form-item>
                          </div>
                        </div>
                      </el-form>
                    </div>
                  </div>
                  <hr class="horizontal dark" />
                </div>
              </div>
              <div class="card--footer" style="margin-top: 20px; margin-bottom: 80px">
                <el-button
                    type="success"
                    :loading="isLoading"
                    @click="submitChecked()"
                >Submit</el-button>
                <el-button
                    type="danger"
                    @click="toggleSelection()"
                >Clear selection</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, onMounted, computed, reactive} from 'vue';
import {fetchLeasedUnits} from "@/api/property-manager/units";
import {fetchPropertyDetails} from "@/api/property-manager/properties";
import moment from "moment/moment";
import Loader from "./../../components/Loader.vue";
import { ElNotification } from "element-plus";
import { sendRentInvoices } from "@/api/kra";
import {useRoute} from "vue-router";
import EmptyStateTable from "../EmptyTableState.vue";
import { postToKra, kraResponse } from "@/api/kra";
import { ElMessage } from "element-plus";
import { downloadInvoicePdf } from "../../../api/property-manager/downloads";
  


const selected = ref([]);
const units = ref([]);
const property = ref([]);
const isLoading = ref(true);
export default {
  components: {
    EmptyStateTable,
    Loader
  },
  setup() {
    const dateFormRef = ref();


    const dateForm = reactive({
      date:  "",
    });

    const messageForm = reactive({
      message:  "",
    });

    const rules = reactive({
      date: [
        {
          required: true,
          message: "Please pick a date",
          trigger: ["blur", "change"],
        },
      ],
      message: [
        {
          required: true,
          message: "Please input your message",
          trigger: ["blur", "change"],
        },
      ],
    });
    const shortcuts = [
      {
        text: 'Today',
        value: new Date(),
      },
      {
        text: 'Tomorrow',
        value: () => {
          const date = new Date()
          date.setTime(date.getTime() + 3600 * 1000 * 24)
          return date
        },
      },
      {
        text: 'Next week',
        value: () => {
          const date = new Date()
          date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
          return date
        },
      },
    ]

    const route = useRoute();
    const multipleTableRef = ref();

    const toggleSelection = () => {
      return selected.value = [];
    };

    const selectAll = computed({
      get() {
        if (units.value && units.value.length > 0) {
          let allChecked = true;
          for (const unit of units.value) {
            if (!selected.value.includes(unit.unitId)) {
              allChecked = false;
            }
            if (!allChecked) break;
          }

          return allChecked;
        }
        return false;
      },
      set(value) {
        const checked = [];
        if (value) {
          units.value.forEach((unit) => {
            checked.push(unit.unitId);
          });
        }

        selected.value = checked;
      },
    });

    const fetchData = async () => {
      const propertyId = route.params.property_id;
      isLoading.value = true;
      try {
        const response = await fetchLeasedUnits(propertyId);
        const propertyInfo = await fetchPropertyDetails(propertyId);

        property.value = propertyInfo.data;
        units.value = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchData();
    });

    const capitalized = (name) => {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);
      return capitalizedFirst + rest;
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const submitChecked = () => {
    if (!selected.value) {
        ElNotification({
            title: "Error",
            message: "Please select user",
            type: "error",
        });
        return;
    }

    isLoading.value = true;
    const formData = new FormData();
    formData.append("unitIds", JSON.stringify(selected.value));

    sendRentInvoices(formData)
        .then(async (data) => {
            if (data.success) {
                const kra_results = [];
                const response = data.data;

                await Promise.all(response.map(async (field) => {
                    const invoice_number = field.invoice_number;
                    const result = await postToKra(field.kra_json);
                    const result_object = {
                        invoice_number: invoice_number,
                        result: result.data,
                    };
                    kra_results.push(result_object);
                }));

                console.log(kra_results);
                kraResponse(kra_results).then((response) => {
                    console.log(response);
                });

                selected.value = [];
                ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                });
            } else if (data.error) {
                selected.value = [];
                ElNotification({
                    title: "Error",
                    message: data.error,
                    type: "error",
                });
            } else {
                Object.values(data.errors).forEach((val) =>
                    ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                    })
                );
            }
        })
        .catch((err) =>
            ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
            })
        )
        .finally(() => {
          fetchData();
            isLoading.value = false;
            
        });
};


    async function downloadInvoice(id){
    try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Invoice will be downloading shortly. Please wait...",
        });
      isLoading.value = true;

        await downloadInvoicePdf(id)
            .then((Response) => {
              console.log(Response)

              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/pdf",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "tenant_invoice_" + Math.round(+new Date() / 1000) + ".pdf"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);

              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    }
    return {
      isLoading,
      multipleTableRef,
      toggleSelection,
      fetchData,
      property,
      selected,
      capitalized,
      dateTime,
      submitChecked,
      selectAll,
      units,
      downloadInvoice,
      rules,
      messageForm,
      dateFormRef,
      dateForm,
      shortcuts
    };
  }
};
</script>

<style scoped>
.with-vertical-line {
  border-right: 1px solid #000;
  padding-right: 0.5rem;
  margin-right:  0.5rem;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
.card-table {
  max-height: 65vh;
  overflow-x: auto;
  overflow-y: auto;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
.card--footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
</style>
