<template>
    <div class="py-4 container-fluid">
      <div class="card">
        <div class="card-header pb-0 mb-5">
          <div class="d-flex align-items-center gap-2">
            <i class="fa fa-credit-card text-primary text-md opacity-10"></i>
            <h6>eTims</h6>
          </div>
        </div>
        <div v-if="isLoading"><Loader /></div>
        <div class="card-body pb-2">
          <section class="card-area">
            <div class="row mb-4">
              <div class="col-md-2">
                <section class="card-section mt-md-4">
                  <div class="role-card">
                    <div class="flip-card">
                      <div class="flip-card__container">
                        <div class="card-front">
                          <div class="card-front__tp card-front__tp--password">
                            <i class="fa fa-file-invoice fa-2x"></i>
                            <h2 class="card-front__heading">Rent Invoice</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="col-md-10">
                <div class="card">
                  <div class="card-body">
                    <div class="card">
                      <div class="card-header">Select Property To Send eTims Invoices Before Payment</div>
                      <hr class="horizontal dark m-0" />
                      <el-form
                          ref="selectPropertyPasswordRef"
                          :model="selectPropertyPassword"
                          :rules="rules"
                          label-width="120px"
                          size=""
                          status-icon
                          :scroll-to-error="true"
                          label-position="top"
                          :hide-required-asterisk="true"
                      >
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-12 mb-3">
                              <el-form-item label="Properties" prop="property">
                                <el-select-v2
                                    v-model="selectPropertyPassword.property"
                                    filterable
                                    :options="properties"
                                    placeholder="Please select a property"
                                    style="width: 100%"
                                />
                              </el-form-item>
                            </div>
                          </div>
                        </div>
                        <div class="card--footer">
                          <el-button
                              type="success"
                              :loading="isLoading"
                              @click="submitPasswordForm(selectPropertyPasswordRef)"
                          >Submit</el-button
                          >
                        </div>
                      </el-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            <div class="row mb-4">
              <div class="col-md-2">
                <section class="card-section mt-md-4">
                  <div class="role-card">
                    <div class="flip-card">
                      <div class="flip-card__container">
                        <div class="card-front">
                          <div class="card-front__tp card-front__tp--mail-invoices">
                            <i class="fa fa-file-invoice fa-2x"></i>
                            <h2 class="card-front__heading">Payment Invoice</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="col-md-10">
                <div class="card">
                  <div class="card-body">
                    <div class="card">
                      <div class="card-header">Select Property To Send eTims Invoices After Payment</div>
                      <hr class="horizontal dark m-0" />
                      <el-form
                          ref="selectPropertyInvoiceRef"
                          :model="selectPropertyInvoice"
                          :rules="rules"
                          label-width="120px"
                          size=""
                          status-icon
                          :scroll-to-error="true"
                          label-position="top"
                          :hide-required-asterisk="true"
                      >
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-12 mb-3">
                              <el-form-item label="Properties" prop="property">
                                <el-select-v2
                                    v-model="selectPropertyInvoice.property"
                                    filterable
                                    :options="properties"
                                    placeholder="Please select a property"
                                    style="width: 100%"
                                />
                              </el-form-item>
                            </div>
  
                          </div>
                        </div>
                        <div class="card--footer">
                          <el-button
                              type="success"
                              :loading="isLoading"
                              @click="submitInvoiceForm(selectPropertyInvoiceRef)"
                          >Submit</el-button
                          >
                        </div>
                      </el-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {reactive, ref} from "vue";
  import { fetchManagersProperties } from "@/api/property-manager/properties";
  import { useRouter } from 'vue-router';
  import Loader from "@/views/components/Loader.vue";
  
  const appointments = ref([]);
  const isLoading = ref(false);
  const selectPropertyRef = ref();
  const selectPropertyScheduleRef = ref();
  const selectPropertyWaterRef = ref();
  const selectPropertyInvoiceRef = ref();
  const selectPropertyPasswordRef = ref();
  const properties = ref([]);
  const tenants = ref([]);
  
  
  export default {
    name: "communications",
    components: {
      Loader
  
    },
    props: {
      property: {
        type: Object,
        required: false,
      },
    },
    setup(props) {
      const router = useRouter();
  
      const selectProperty = reactive({
        property: props?.property?.property || "",
      });
  
      const selectPropertySchedule = reactive({
        property: props?.property?.property || "",
      });
  
      const selectPropertyWater = reactive({
        property: props?.property?.property || "",
      });
  
      const selectPropertyInvoice = reactive({
        property: props?.property?.property || "",
      });
  
      const selectPropertyPassword = reactive({
        property: props?.property?.property || "",
      });
  
      const rules = reactive({
  
        property: [
          {
            required: true,
            message: "Please select property",
            trigger: ["blur", "change"],
          },
        ],
      });
      const submitForm = async (formEl) => {
        if (!formEl) return;
        await formEl.validate(async (valid) => {
          if (valid) {
            isLoading.value = true;
            try {
              let property_id = selectProperty.property;
  
              if (router) {
                router.push(`/blast-composed-messages/${property_id}`);
              } else {
                console.error("Router object is undefined");
              }
            } catch (error) {
              console.log(error);
            }
            isLoading.value = false;
          }
        });
      };
  
      const submitScheduleForm = async (formEl) => {
        if (!formEl) return;
        await formEl.validate(async (valid) => {
          if (valid) {
            isLoading.value = true;
            try {
              let property_id = selectPropertySchedule.property;
  
              console.log(property_id)
              if (router) {
                router.push(`/schedule-message/${property_id}`);
              } else {
                console.error("Router object is undefined");
              }
            } catch (error) {
              console.log(error);
            }
            isLoading.value = false;
          }
        });
      };
  
      const submitWaterForm = async (formEl) => {
        if (!formEl) return;
        await formEl.validate(async (valid) => {
          if (valid) {
            isLoading.value = true;
            try {
              let property_id = selectPropertyWater.property;
  
              if (router) {
                router.push(`/blast-water-bill-message/${property_id}`);
              } else {
                console.error("Router object is undefined");
              }
            } catch (error) {
              console.log(error);
            }
            isLoading.value = false;
          }
        });
      };
      const submitInvoiceForm = async (formEl) => {
        if (!formEl) return;
        await formEl.validate(async (valid) => {
          if (valid) {
            isLoading.value = true;
            try {
              let property_id = selectPropertyInvoice.property;
  
              if (router) {
                router.push(`/payment-invoice/${property_id}`);
              } else {
                console.error("Router object is undefined");
              }
            } catch (error) {
              console.log(error);
            }
            isLoading.value = false;
          }
        });
      };
  
      const submitPasswordForm = async (formEl) => {
        if (!formEl) return;
        await formEl.validate(async (valid) => {
          if (valid) {
            isLoading.value = true;
            try {
              let property_id = selectPropertyPassword.property;
  
              if (router) {
                router.push(`/rent-invoice/${property_id}`);
              } else {
                console.error("Router object is undefined");
              }
            } catch (error) {
              console.log(error);
            }
            isLoading.value = false;
          }
        });
      };
  
  
      return {
        selectPropertyInvoice,
        selectPropertyScheduleRef,
        submitScheduleForm,
        selectPropertySchedule,
        submitWaterForm,
        submitPasswordForm,
        submitInvoiceForm,
        selectPropertyInvoiceRef,
        selectPropertyWater,
        selectProperty,
        selectPropertyRef,
        selectPropertyWaterRef,
        selectPropertyPasswordRef,
        selectPropertyPassword,
        appointments,
        isLoading,
        submitForm,
        properties,
        rules,
        tenants
      };
    },
  
    created() {
      this.fetchPropertiesList();
    },
    methods: {
      async fetchPropertiesList() {
        try {
          const response = await fetchManagersProperties();
  
          const tempProperties = response.data.map((property) => ({
            value: `${ property.propertyId }`,
            label: `${ property.propertyName }`,
          }));
  
          properties.value = tempProperties;
          console.log(properties)
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
  </script>
  <style scoped>
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
  }
  h6 {
    margin: 0;
  }
  </style>
  <style scoped>
  .card-front__heading {
    font-size: 1rem;
    margin-top: 0.5rem;
    font-weight: 600;
  }
  
  .card-front__text-view {
    font-size: 0.85rem;
    font-weight: 500;
    margin-top: 0.2rem;
  }
  
  .card-front__text-view--admin {
    color: #ff62b2;
  }
  
  .card-front__text-view--doctor {
    color: #2aaac1;
  }
  
  .card-front__text-view--receptionist {
    color: #fa7f67;
  }
  
  /* Front cover */
  
  .card-front__tp {
    color: #fafbfa;
  }
  
  .card-front__icon {
    fill: #fafbfa;
    font-size: 3vw;
    height: 3.25rem;
    margin-top: -0.5rem;
    width: 3.25rem;
  }
  
  .main {
    background: linear-gradient(to bottom right, #eee8dd, #e3d9c6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: 100%;
  }
  
  .card-section {
    align-items: flex-start;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
  
  .role-card {
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: -0.1rem 1.7rem 6.6rem -3.2rem rgba(0, 0, 0, 0.5);
    width: 7em;
  }
  
  .card-front {
    background-color: #fafbfa;
  }
  
  .card-front__tp {
    align-items: center;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 57% 90%, 50% 100%, 43% 90%, 0 90%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.2rem;
    border-radius: 4px;
  }
  
  .card-front__tp--password {
    background: linear-gradient(to bottom, #ff73b9, #ff40a1);
  }
  
  .card-front__tp--blast-sms {
    background: linear-gradient(to bottom, #47c2d7, #279eb2);
  }
  
  .card-front__tp--blast-mail {
    background: linear-gradient(to bottom, #fb9b88, #f86647);
  }
  
  .card-front__tp--sms-invoice {
    background: linear-gradient(to bottom, #463cc2, #2a0fd9);
  }
  
  .card-front__tp--mail-invoices {
    background: linear-gradient(to bottom, #3eb055, #23d523);
  }
  
  .card-front__tp--sms-water-bill {
    background: linear-gradient(to bottom, #a67b3c, #f1cb0d);
  }
  
  .card-front__tp--sms-schedule {
    background: linear-gradient(to bottom, #733488, #b309e3);
  }
  
  
  .card-header {
    border-bottom: 1px solid #e9ecef;
    padding: 1.2rem !important;
  }
  .card--footer {
    display: flex;
    justify-content: space-between;
    padding: 0 1.2rem 1.2rem 1.2rem !important;
  }
  
  /* Front card's bottom section */
  .card-front__bt {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  ol {
    list-style: none;
    padding: 1rem 0;
  }
  
  ol li:before {
    content: "✓";
    color: green;
  }
  </style>
  