<template>
  <div class="py-4 container-fluid">
    <div v-if="isLoading"><Loader /></div>
    <div class="card">
      <el-page-header :style="{ color: 'black', padding: '10px' }" @back="goBack">
        <template #icon>
          <i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 15px;"></i>
        </template>
      </el-page-header>
      <div class="card-body pb-2 mt-0">
        <section class="card-area">
          <el-tabs v-model="activeName" class="demo-tabs" @tab-click.native="handleClick">
            <el-tab-pane name="units" v-if="hasPermission('view.units')">
              <template #label>
                <i class="fa fa-home" aria-hidden="true"></i>&nbsp; &nbsp;  Units
              </template>
              <property-units-table
                :units="propertyUnits"
                :refresh="fetchUnits"
                :propertyId="propertyId"
              />
            </el-tab-pane>
            <el-tab-pane name="payments" v-if="hasPermission('view.payments')">
              <template #label>
                <i class="fa fa-money" aria-hidden="true"></i>&nbsp; &nbsp;  Payments
              </template>
              <property-payments-table
                :payments="propertyPayments"
                :refresh="fetchPayments"
                :propertyId="propertyId"
              />
            </el-tab-pane>
            <el-tab-pane name="uploads">
              <template #label>
                <i class="fa fa-cloud-upload" aria-hidden="true"></i>&nbsp; &nbsp; Uploads
              </template>
              <property-uploads-table
                  :refresh="fetchUnits"
                  :propertyId="propertyId"
              />
            </el-tab-pane>
            <el-tab-pane name="expenses"  v-if="hasPermission('view.property_expenses')">
              <template #label>
                <i class="fa fa-table" aria-hidden="true"></i>&nbsp; &nbsp;  Expenses
              </template>
              <expenses-table
                  :refresh="fetchExpensesFn"
                  :expenses="expenses"
                  :searchQuery="searchTerm"
                  :propertyId="propertyId"
              />
            </el-tab-pane>
            <el-tab-pane name="docs_uploads">
              <template #label>
                <i class="fa fa-file-text" aria-hidden="true"></i>&nbsp; &nbsp;  Documents
              </template>
              <tenant-documents-table
                  :search-query="searchTerm"
                  :refresh="fetchTenantsDocuments"
                  :propertyId="propertyId"
                  :tenantDocument="tenantDocument"
              />
            </el-tab-pane>
            <el-tab-pane name="delete_units"  v-if="hasPermission('delete.units')">
              <template #label>
                <i class="fa fa-trash" aria-hidden="true"></i>&nbsp; &nbsp;  Delete Units
              </template>
              <property-vacant-units-table
                :units="propertyVacantUnits"
                :refresh="fetchVacantUnits"
                :searchQuery="searchTerm"
                :propertyId="propertyId"
              />
            </el-tab-pane>
          </el-tabs>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import PropertyUnitsTable from "../../views/components/property-manager/PropertyUnitsTable.vue";
import PropertyPaymentsTable from "../../views/components/property-manager/PropertyPaymentsTable.vue";
import PropertyVacantUnitsTable from "../../views/components/property-manager/PropertyVacantUnitsTable.vue";
import PropertyUploadsTable from "../../views/components/property-manager/PropertyUploadsTable.vue";
import ExpensesTable from "../../views/components/property-manager/ExpensesTable.vue";
import TenantDocumentsTable from "../../views/components/property-manager/TenantDocumentsTable.vue";
import Loader from "../../views/components/Loader.vue";
import router from "../../router";
import moment from "moment";
import { fetchPropertyUnits, fetchPropertyVacantUnits } from "../../api/property-manager/units";
import { fetchPropertyPayments } from "../../api/property-manager/payments";
import { fetchPropertiesExpenses } from "../../api/property-manager/expenses";
import { fetchTenantDocuments } from "../../api/property-manager/documents";


const isLoading = ref(false);
const propertyId = ref(null);
const propertyUnits = ref([]);
const propertyPayments = ref([]);
const propertyVacantUnits = ref([]);
const tenantDocument = ref([]);
const expenses = ref([]);
const activeName = ref('units');
const searchTerm = ref("");


export default {
  name: "property-drawer",
  components: {
    Loader,
    PropertyUnitsTable,
    PropertyPaymentsTable,
    PropertyUploadsTable,
    PropertyVacantUnitsTable,
    TenantDocumentsTable,
    ExpensesTable,
  },
  setup() {
    const handleClick = () => {}

    const fetchUnits = async (filter, page) => {
      isLoading.value = true;
      let entity_params = {};
      try {
        if (filter === undefined) {
          entity_params = ref({});
        } else {
          entity_params = filter;
        }
        const response = await fetchPropertyUnits(entity_params, page, propertyId.value);
        propertyUnits.value = response.data;
      } catch (error) {
        console.log(error)
      } finally {
        isLoading.value = false;
      }
    }

    const fetchPayments = async (filter, page) => {
      isLoading.value = true;
      let entity_params = {};
      try {
        if (filter === undefined) {
          entity_params = ref({});
        } else {
          entity_params = filter;
        }
        const response = await fetchPropertyPayments(entity_params, page, propertyId.value);
        propertyPayments.value = response.data;
      } catch (error) {
        console.log(error)
      } finally {
        isLoading.value = false;
      }
    }

    const fetchVacantUnits = async (search, page) => {
      searchTerm.value = search;
      isLoading.value = true;
      try{
        isLoading.value = true;
        const response = await fetchPropertyVacantUnits(search, page, propertyId.value);
        propertyVacantUnits.value = response.data;
      } catch (error) {
        console.log(error)
      } finally {
        isLoading.value = false;
      }
    }

    const fetchTenantsDocuments = async (search, page) => {
      searchTerm.value = search;
      isLoading.value = true;
      try{
        isLoading.value = true;
        const response = await fetchTenantDocuments(search, page, propertyId.value);
        tenantDocument.value = response.data;
      } catch (error) {
        console.log(error)
      } finally {
        isLoading.value = false;
      }
    }

    const fetchExpensesFn = async (search, page) => {
      searchTerm.value = search;
      isLoading.value = true;
      try {
        const response = await fetchPropertiesExpenses(search, page, propertyId.value);
        expenses.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      const propertyIdFromRoute = router.currentRoute.value.params.id;
      propertyId.value = propertyIdFromRoute;
      fetchUnits();
    });

    watch(activeName, async (newTab, oldTab) => {
      console.log("Active tab changed from", oldTab, "to", newTab);
      if (newTab === "units") {
        fetchUnits();
      } else if (newTab === "payments"){
        fetchPayments()
        console.log("Active tab changed from", oldTab, "to", newTab);
      } else if (newTab === "uploads"){
        console.log("Active tab changed from", oldTab, "to", newTab);
      } else if (newTab === "expenses"){
        fetchExpensesFn();
        console.log("Active tab changed from", oldTab, "to", newTab);
      } else if (newTab === "docs_uploads"){
        fetchTenantsDocuments()
        console.log("Active tab changed from", oldTab, "to", newTab);
      } else if (newTab === "delete_units"){
        fetchVacantUnits()
        console.log("Active tab changed from", oldTab, "to", newTab);
      }
    });

    const capitalized = (name) => {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);
      return capitalizedFirst + rest;
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const goBack = () => {
      router.push({ path: `/property/${propertyId.value}` });
    }

    

    return {
      dateTime,
      capitalized,
      isLoading,
      activeName,
      handleClick,
      fetchUnits,
      fetchPayments,
      fetchExpensesFn,
      fetchVacantUnits,
      fetchTenantsDocuments,
      expenses,
      propertyUnits,
      propertyPayments,
      propertyVacantUnits,
      tenantDocument,
      goBack,
      searchTerm,
      propertyId
    };
  },
  data(){ 
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      userPermissions: storedPermissions || [],

    };
    },
    methods: {
      hasPermission(slug) {
        return this.userPermissions.includes(slug);
      },
    }, 
};
</script>

<style scoped>

h6 {
  margin: 0;
}
</style>
<style scoped>

.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}


ol {
  list-style: none;
  padding: 1rem 0;
}

ol li:before {
  content: "✓";
  color: green;
}
</style>
