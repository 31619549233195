<template>
  <div class="card">
    <el-page-header :style="{ color: 'black', padding: '10px' }" @back="goBack">
      <template #icon>
        <i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 15px;"></i>
      </template>
    </el-page-header>
    <el-form
        ref="accountFormRef"
        :model="accountForm"
        label-width="100px"
        status-icon
        :scroll-to-error="true"
        label-position="top"
        :hide-required-asterisk="true"
    >
        <div class="card-header pb-0 mb-5">
          <div class="d-flex align-items-center gap-2">
            <i class="fa fa-line-chart text-primary text-md opacity-10"></i>
            <h6>Properties Accounting Report</h6>
          </div>
          <el-button-group>
            <el-button
                type="success"
                @click="submitExcelForm(accountFormRef)"
            >
              <i class="fa fa-stack-exchange" aria-hidden="true"></i>&nbsp; Excel
            </el-button>
            <el-button
                type="warning"
                @click="downloadPdf(accountFormRef)"
            >
              <i class="fa fa-file-text-o" aria-hidden="true"></i>&nbsp; PDF
            </el-button>
          </el-button-group>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
          <div class="row mb-6">
            <div class="col-6">
                <div style="text-align: center">
                    <el-transfer
                        v-model="accountForm.selectedProperties"
                        style="text-align: left; display: inline-block"
                        filterable
                        :render-content="renderFunc"
                        :titles="['Properties', 'Selected']"
                        :format="{
                    noChecked: '${total}',
                    hasChecked: '${checked}/${total}',
                  }"
                      :data="data"
                      @change="handleChange"
                  >
                  </el-transfer>
                </div>
            </div>
            <div class="col-3">
              <div class="demo-date-picker">
                  <el-date-picker
                      v-model="accountForm.dateRange"
                      type="daterange"
                      unlink-panels
                      range-separator="To"
                      start-placeholder="Start date"
                      end-placeholder="End date"
                      :shortcuts="shortcuts"
                />
              </div>
            </div>
            <div class="col-3">
          <el-radio-group v-model="accountForm.selectedRadio">
            <el-radio-button label="Agent" />
<!--            <el-radio-button label="Landlord" />-->
            <el-radio-button label="Tax" />
          </el-radio-group>
        </div>
      </div>
        </div>
    </el-form>
  </div>
</template>

<script>

import {reactive, ref} from 'vue'
import {fetchManagersProperties} from "../../../api/property-manager/properties";
import router from "@/router";
import {ElMessage} from "element-plus";
import {
  managerDownloadAccountingReportExcel,
  managerDownloadAccountingReportPdf
} from "../../../api/property-manager/downloads";

const properties = ref([]);
const isLoading = ref(false);
const accountFormRef = ref();

export default {
  name: "accounts-view",

  setup() {

    const properties = ref([]);
    const data = ref([]);
    const rightValue = ref([]);
    const leftValue = ref([]);

    const accountForm = reactive({
      selectedProperties: [],
      dateRange: [],
      selectedRadio: 'Agent'
    });

    const renderFunc = (h, option) => {
      return h('span', null, option.label);
    };

    const handleChange = (value, direction, movedKeys) => {
      console.log(value, direction, movedKeys);
    };

    const generateData = (properties) => {
      const data = properties.map((property) => ({
        key: property.value,
        label: property.label,
        disabled: false,
      }));
      return data;
    };
    const fetchPropertiesList = async () => {
      try {
        const response = await fetchManagersProperties();
        const tempProperties = response.data.map((property) => ({
          value: `${property.propertyId}`,
          label: `${property.propertyName}`,
        }));
        properties.value = tempProperties; // Update the properties ref
        data.value = generateData(properties.value);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPropertiesList();

    const shortcuts = [
      {
        text: 'Last week',
        value: function() {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        }
      },
      {
        text: 'Last month',
        value: function() {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        }
      },
      {
        text: 'Last 3 months',
        value: function() {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end];
        }
      }
    ];
    const goBack = () => {
      router.go(-1);
    }

    const downloadPdf = async () => {
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Your report will be downloading shortly. Please wait...",
        });
        const formData = new FormData();
        formData.append('date', accountForm.dateRange.join(' - '));
        formData.append('propertyIds', accountForm.selectedProperties.join(','));
        formData.append('type', accountForm.selectedRadio);
        formData.append('format', "pdf");
        await managerDownloadAccountingReportPdf(formData)
            .then((Response) => {
              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/pdf",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "accounting_" + Math.round(+new Date() / 1000) + ".pdf"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);

              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };

    const submitExcelForm = async () => {
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Your report will be downloading shortly. Please wait...",
        });
        isLoading.value = true;
        const formData = new FormData();
        formData.append('date', accountForm.dateRange.join(' - '));
        formData.append('propertyIds', accountForm.selectedProperties.join(','));
        formData.append('type', accountForm.selectedRadio);
        formData.append('format', "excel");
        await managerDownloadAccountingReportExcel(formData)
            .then((Response) => {
              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/vnd.ms-excel",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "accounting_" + Math.round(+new Date() / 1000) + ".xlsx"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);
              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };

    return {
      handleChange,
      renderFunc,
      data,
      shortcuts,
      leftValue,
      rightValue,
      goBack,
      downloadPdf,
      accountForm,
      submitExcelForm,
      accountFormRef
    };
  },

  created() {
    this.fetchPropertiesList();
  },
  methods: {
    async fetchPropertiesList() {
      try {
        const response = await fetchManagersProperties();

        properties.value = response.data.map((property) => ({
          value: `${property.propertyId}`,
          label: `${property.propertyName}`,
        }));
        console.log(properties)
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.transfer-footer {
  margin-left: 15px;
  padding: 6px 5px;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
.demo-date-picker {
  display: flex;
  width: 70%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.card-body {
  max-height: 50vh;
  max-width: 120%; /* Increase max-width to allow for horizontal scrolling */
  overflow-x: auto;
  overflow-y: auto;
  padding: 1.5rem;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
h6 {
  margin: 0;
}
</style>
