<template>
  <div class="card">
    <div class="pb-0 card-header text-start">
      <h4 class="font-weight-bolder">Current Month Stats</h4>
    </div>
    <div class="card-body">
        <div class="row">

          <div class="col-md-6">
            <p class="mb-0">Current Month Stats</p>
            <bar-chart/>
          </div>
          <div class="col-md-6">
            <p class="mb-0">Current Month Stats</p>
            <bar-chart/>
          </div>
          <div class="col-md-6">
            <p class="mb-0">Current Month Stats</p>
            <bar-chart/>
          </div>
          <div class="col-md-6">
            <bar-chart/>
          </div>
          <div class="col-md-6">
            <bar-chart/>
          </div>

          <div class="col-md-6">
            <bar-chart/>
          </div>

          <div class="col-md-6 mt-8">

          </div>
        </div>

    </div>
  </div>
</template>

<script>

import BarChart from "./Charts/BarChart.vue";
// import DoughnutChart from "./Charts/DoughnutChart.vue";
export default {
  name: "ChartsTable",
  components: {
    // DoughnutChart,
    BarChart
  }
}
</script>


<style scoped>

</style>