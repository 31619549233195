import axios from "axios";
import { api } from "./";

// const kraUrl = "http://localhost:8080/VsdcConnector_v1/vsdc/saveSales";
const kraUrl = "http://localhost:8000/api/health";

export function postToKra(data) {
  const dataJson = data;
  return axios.post(kraUrl, dataJson, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
    },
  });
}



export const sendRentInvoices = async(body) => {
  let response = await api
      .post("kra-rent-invoices", body)
      .then((response) => response.data)
      .catch((e) => e.response.data);
  return response;
};

export const kraResponse = async(body) => {
  let response = await api
      .post("kra-invoices-response", body)
      .then((response) => response.data)
      .catch((e) => e.response.data);
  return response;
};

export const sendPaymentInvoices = async(body) => {
  let response = await api
      .post("kra-payment-invoices", body)
      .then((response) => response.data)
      .catch((e) => e.response.data);
  return response;
};

export const kraPaymentResponse = async(body) => {
  let response = await api
      .post("kra-payment-invoices-response", body)
      .then((response) => response.data)
      .catch((e) => e.response.data);
  return response;
};


export const sendManagerInvoices = async(body) => {
  let response = await api
      .post("kra-manager-invoices", body)
      .then((response) => response.data)
      .catch((e) => e.response.data);
  return response;
};

export const kraManagerInvoiceResponse = async(body) => {
  let response = await api
      .post("kra-manager-invoices-response", body)
      .then((response) => response.data)
      .catch((e) => e.response.data);
  return response;
};

