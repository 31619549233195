<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar isBtn="bg-gradient-light" />
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg'); background-position: top;"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h3 class="text-white mb-2 mt-5">Simplify Rent Collection</h3>
            <p
              class="text-lead text-white"
            >Digitize rent payment and property management for seamless reconciliation, reporting, and communication with tenants.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>Email Verification</h5>
              <p>Click login button</p>
            </div>
            <div class="row px-xl-5 px-sm-4 px-3">
            </div>
            <div class="card-body">
              <el-form
                  ref="verifyForm"
                  :model="emailVerificationForm"
                  label-width="auto"
                  class="demo-ruleForm"
                  size="large"
                  status-icon
                  :scroll-to-error="true"
                  label-position="top"
                  :hide-required-asterisk="true"
              >  
              <el-button
                type="success"
                class="px-5 d-block my-4 mx-auto"
                :loading="isLoading"
                @click="submitForm(verifyForm)"
              >Login</el-button>
            </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template> 
  
  <script>
  import { reactive, ref, h } from "vue";
  import { ElNotification, ElMessage } from "element-plus";
  import { verifyEmail } from "../api/services/authentication";
  import { useRouter } from "vue-router";
  import { useRoute } from "vue-router";
  import Navbar from "@/components/PageLayout/Navbar.vue";
  import AppFooter from "@/components/PageLayout/Footer.vue";

  const body = document.getElementsByTagName("body")[0];
  const verifyForm = ref();
  const isLoading = ref(false);
  
  export default {
    components: {
      Navbar,
      AppFooter,
    },
    setup() {
      const router = useRouter();
      const emailVerificationForm = reactive({
        token: "",
      });
  

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;

          let body = {
            grant_type: "password",
            client_id: process.env.VUE_APP_API_KEY,
            client_secret: process.env.VUE_APP_API_SECRET,
            token: emailVerificationForm.token,
          };

          verifyEmail(body, emailVerificationForm.token)
            .then((response) => {
              console.log(response);

              if (response.status == 200) {
                localStorage.setItem("isAuthenticated", true);
                localStorage.setItem("token", response.data.token.access_token);
                localStorage.setItem("user",JSON.stringify(response.data.user));
                localStorage.setItem("profileName",response.data.user.name);

                router.replace({ path: "/dashboard-default" });
                ElMessage({
                  message: h(
                    "i",
                    { style: "color: teal" },
                    `Welcome ${
                      response.data.user.name
                    } to your dashboard`
                  ),
                  type: "success",
                });
              } else if (response.status == 422) {
                console.log(response)
                ElNotification({
                  title: "Error",
                  message:
                    "Invalid access token.",
                  type: "error",
                });
              } else {
                ElNotification({
                  title: "Error",
                  message: response.data.message,
                  type: "error",
                });
              }
            })
            .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
            )
            .finally(() => {
              isLoading.value = false;
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

      return {
        isLoading,
        submitForm,
        emailVerificationForm,
        verifyForm,
      };
    },
  
    created() {
      this.$store.state.hideConfigButton = true;
      this.$store.state.showNavbar = false;
      this.$store.state.showSidenav = false;
      this.$store.state.showFooter = false;
      body.classList.remove("bg-gray-100");
      const route = useRoute();
      const id = route.params.id;
      this.emailVerificationForm.token = id;
    },
    beforeUnmount() {
      this.$store.state.hideConfigButton = false;
      this.$store.state.showNavbar = true;
      this.$store.state.showSidenav = true;
      this.$store.state.showFooter = true;
      body.classList.add("bg-gray-100");
    },
  };
  </script>
  <style scoped>
  .el-button .custom-loading .circular {
    margin-right: 6px;
    width: 18px;
    height: 18px;
    animation: loading-rotate 2s linear infinite;
  }
  .click-here{
    margin-left: 1.25rem;
  }
  </style>
  