<template>
  <el-popover
      placement="bottom"
      title="Filter Cases"
      :width="1000"
      trigger="click"
      :visible="filter_visible"
  >
    <template #reference>
      <el-button
          class="m-2 btn-xs null null ms-auto"
          type="primary"
          @click="filter_visible = !filter_visible"
      ><i class="fa fa-filter me-1" aria-hidden="true"></i>&nbsp;Filter
      </el-button>
    </template>
    <el-form
        ref="filter_paramsRef"
        label-width="0px"
        size=""
        status-icon
        :scroll-to-error="true"
        label-position="top"
        :hide-required-asterisk="true"
        style="margin-bottom: 20px;"
    >
      <div>
        <hr>
      </div>
      <div v-if="show_payments_table_filter" class="row">
          <div class="col-sm-3 col-md-4">
            <label for="status" class="form-control-label">Tenants Name</label>
            <el-form-item  class="class-body" prop="tenant">
              <el-select-v2
                  v-model="filter_params.tenant"
                  filterable
                  :options="tenants"
                  placeholder="Please select a tenant"
                  style="width: 100%"
              />
            </el-form-item>
          </div>
          <div class="col-sm-3 col-md-4">
            <label for="status" class="form-control-label">Property Manager Name</label>
            <el-form-item  class="class-body">
              <el-select-v2
                  v-model="filter_params.propertyManagerId"
                  filterable
                  :options="property_managers"
                  placeholder="Please select property manager"
                  style="width: 100%"
              />
            </el-form-item>
          </div>
          <div class="col-sm-3 col-md-4">
            <label for="status" class="form-control-label">Unit Code</label>
            <el-form-item  class="class-body">
              <el-input
                  v-model="filter_params.unit_code"
                  placeholder="Please input unit code"
                  clearable
              />
            </el-form-item>
          </div>
          <div class="col-sm-3 col-md-4">
            <label for="status" class="form-control-label">Transaction Reference</label>
            <el-form-item  class="class-body">
              <el-input
                  v-model="filter_params.reference_code"
                  placeholder="Please input reference code"
                  clearable
              />
            </el-form-item>
          </div>
          <div class="col-sm-3 col-md-4">
            <label for="status" class="form-control-label">Payment Source</label>
            <el-form-item  class="class-body">
              <el-input
                  v-model="filter_params.payment_source"
                  placeholder="Please input payment source"
                  clearable
              />
            </el-form-item>
          </div>
          <div class="col-sm-3 col-md-4">
            <label for="status" class="form-control-label">Date Range</label>
            <el-form-item prop="date_range">
              <el-date-picker
                  v-model="filter_params.date_range"
                  style="width: 100%"
                  type="daterange"
                  unlink-panels
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  :shortcuts="shortcuts"
              />
            </el-form-item>
          </div>
      </div>

      <div v-if="show_logs_table_filter" class="row">
        <div class="col-sm-3 col-md-6">
          <label for="status" class="form-control-label">Name</label>
          <el-form-item  class="class-body">
            <el-input
                v-model="filter_params.user_name"
                placeholder="Please input name"
                clearable
            />
          </el-form-item>
        </div>
         
          <div class="col-sm-3 col-md-6">
            <label for="status" class="form-control-label">Date Range</label>
            <el-form-item prop="date_range">
              <el-date-picker
                  v-model="filter_params.date_range"
                  style="width: 100%"
                  type="daterange"
                  unlink-panels
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  :shortcuts="shortcuts"
              />
            </el-form-item>
          </div>
      </div>


      <div v-if="show_sms_table_filter" class="row">
        <div class="col-sm-3 col-md-6">
          <label for="status" class="form-control-label">Phone</label>
          <el-form-item  class="class-body">
            <el-input
                v-model="filter_params.phone"
                placeholder="Please input phone number"
                clearable
            />
          </el-form-item>
        </div>
         
          <div class="col-sm-3 col-md-6">
            <label for="status" class="form-control-label">Date Range</label>
            <el-form-item prop="date_range">
              <el-date-picker
                  v-model="filter_params.date_range"
                  style="width: 100%"
                  type="daterange"
                  unlink-panels
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  :shortcuts="shortcuts"
              />
            </el-form-item>
          </div>
      </div>

      <div v-if="show_unallocated_payments_table_filter" class="row">
          <div class="col-sm-3 col-md-6">
            <label for="status" class="form-control-label">Transaction Reference</label>
            <el-form-item  class="class-body">
              <el-input
                  v-model="filter_params.reference_code"
                  placeholder="Please input reference code"
                  clearable
              />
            </el-form-item>
          </div>
          <div class="col-sm-3 col-md-6">
            <label for="status" class="form-control-label">Payment Source</label>
            <el-form-item  class="class-body">
              <el-input
                  v-model="filter_params.payment_source"
                  placeholder="Please input payment source"
                  clearable
              />
            </el-form-item>
          </div>
        <div class="col-sm-3 col-md-6">
          <label for="status" class="form-control-label">Status</label>
          <el-form-item prop="allocation_status">
            <el-select
                class="w-100"
                placeholder="Select status"
                v-model="filter_params.allocation_status"
            >
              <el-option label="Allocated" value="1" />
              <el-option label="Unallocated" value="0"/>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-sm-3 col-md-6">
            <label for="status" class="form-control-label">Date Range</label>
            <el-form-item prop="date_range">
              <el-date-picker
                  v-model="filter_params.date_range"
                  style="width: 100%"
                  type="daterange"
                  unlink-panels
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  :shortcuts="shortcuts"
              />
            </el-form-item>
          </div>
      </div>

      <div v-if="show_property_units_table_filter" class="row">
        <div class="col-sm-3 col-md-4">
          <label for="status" class="form-control-label">Unit Code</label>
          <el-form-item  class="class-body">
            <el-input
                v-model="filter_params.unit_code"
                placeholder="Please input unit code"
                clearable
            />
          </el-form-item>
        </div>
        <div class="col-sm-3 col-md-4">
            <label for="status" class="form-control-label">Rental Rate</label>
            <el-form-item  class="class-body">
              <el-input
                  v-model="filter_params.rental_rate"
                  placeholder="Please input payment source"
                  clearable
              />
            </el-form-item>
          </div>
        <div class="col-sm-3 col-md-4">
          <label for="status" class="form-control-label">Lease Status</label>
          <el-form-item prop="lease_status">
            <el-select
                class="w-100"
                placeholder="Select status"
                v-model="filter_params.lease_status"
            >
              <el-option label="Leased" value="1" />
              <el-option label="Not Leased" value="0" />
            </el-select>
          </el-form-item>
        </div>
      </div>

      <div v-if="show_tenants_table_filter" class="row">
        <div class="col-sm-3 col-md-6">
            <label for="status" class="form-control-label">Property Manager Name</label>
            <el-form-item  class="class-body">
              <el-select-v2
                  v-model="filter_params.propertyManagerId"
                  filterable
                  :options="property_managers"
                  placeholder="Please select property manager"
                  style="width: 100%"
              />
            </el-form-item>
          </div>
          <div class="col-sm-3 col-md-6">
            <label for="status" class="form-control-label">Tenant Name</label>
            <el-form-item  class="class-body">
              <el-input
                  v-model="filter_params.tenant_name"
                  placeholder="Please input tenant name"
                  clearable
              />
            </el-form-item>
          </div>
        <div class="col-sm-3 col-md-6">
            <label for="status" class="form-control-label">Tenant Email</label>
            <el-form-item  class="class-body">
              <el-input
                  v-model="filter_params.tenant_email"
                  placeholder="Please input tenant email"
                  clearable
              />
            </el-form-item>
          </div>
          <div class="col-sm-3 col-md-6">
            <label for="status" class="form-control-label">Lease Status</label>
            <el-form-item prop="lease_status">
              <el-select
                  class="w-100"
                  placeholder="Select status"
                  v-model="filter_params.lease_status"
              >
                <el-option label="Leased" value="1" />
                <el-option label="Not Leased" value="0" />
              </el-select>
            </el-form-item>

          </div>
      </div>

      <div v-if="show_properties_table_filter" class="row">
          <div class="col-sm-3 col-md-6">
            <label for="status" class="form-control-label">Search By Property</label>
            <el-form-item  class="class-body" prop="propertyId">
              <el-input
                  v-model="filter_params.property_name"
                  placeholder="Please input property name"
                  clearable
              />
            </el-form-item>
          </div>

          <div class="col-sm-3 col-md-6">
            <label for="status" class="form-control-label">Property Manager Name</label>
            <el-form-item  class="class-body">
              <el-select-v2
                  v-model="filter_params.propertyManagerId"
                  filterable
                  :options="property_managers"
                  placeholder="Please select property manager"
                  style="width: 100%"
              />
            </el-form-item>
          </div>
      </div>
      <div v-if="show_propertyManagers_table_filter" class="row">
        <label for="status" class="form-control-label">Property Manager Name</label>
            <el-form-item  class="class-body">
              <el-input
                  v-model="filter_params.propertyManager_name"
                  placeholder="Please input property manager name"
                  clearable
              />
            </el-form-item>
      </div>

      <div class="row">
        <div class="col-md-6">
          <el-button
              class="col-md-6 custom-green-button"
              style="width: 100%;"
              @click="filterList"
          >Filter</el-button>
        </div>
        <div class="col-md-6">
          <el-button
              type="danger"
              class="col-md-6"
              style="width: 100%;"
              @click="closeFilter"
          >Cancel</el-button>
        </div>
      </div>
    </el-form>
  </el-popover>
</template>

<script>

import {reactive, ref} from "vue";
import { fetchLeasedManagerTenants } from "../../../api/admin/tenants";
import { fetchManagersProperties } from "../../../api/admin/properties";
import {fetchManagerNotPaginatedLandlords} from "../../../api/admin/landlords";
import {fetchPropertyManagersData} from "../../../api/admin/managers";



const filter_visible = ref(false);
const tenants = ref([]);
const properties = ref([]);
const landlords = ref([]);
const property_managers = ref([]);
const filter_paramsRef = ref();
const shortcuts = [
  {
    text: 'Last week',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    },
  },
  {
    text: 'Last month',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    },
  },
  {
    text: 'Last 3 months',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start, end]
    },
  },
];
export default {
  name: "FilterPaymentsForm",

  props: {
    show_payments_table_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_logs_table_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_sms_table_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_property_units_table_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_unallocated_payments_table_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_tenants_table_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    filter_init: {
      type: Object,
      required: false,
    },
    filterCallBack: {
      type: Function,
      required: true,
    },
    show_properties_table_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_propertyManagers_table_filter: {
      type: Boolean,
      required: false,
      default: false,
    },

  },
  setup(props) {
    const filter_params = reactive({
      search_query: props.filter_init.search_query ?? "",
      tenant: props.filter_init.tenant ?? null,
      propertyId: props.filter_init.propertyId ?? null,
      unit_code: props.filter_init.unit_code ?? null,
      user_name: props.filter_init.user_name ?? null,
      phone: props.filter_init.phone ?? null,
      date_range: props.filter_init.date_range ?? null,
      reference_code: props.filter_init.reference_code ?? null,
      payment_source: props.filter_init.payment_source ?? null,
      rental_rate: props.filter_init.rental_rate ?? null,
      lease_status: props.filter_init.lease_status ?? null,
      allocation_status: props.filter_init.allocation_status ?? null,
      tenant_name: props.filter_init.tenant_name ?? null,
      tenant_email: props.filter_init.tenant_email ?? null,
      landlordId: props.filter_init.landlordId ?? null,
      propertyManagerId: props.filter_init.propertyManagerId ?? null,
      propertyManager_name: props.filter_init.propertyManager_name ?? null,
      property_name: props.filter_init.property_name ?? null,
    });
    const closeFilter = () => {
      filter_params.search_query = "";
      filter_params.tenant = "";
      filter_params.propertyId = "";
      filter_params.unit_code = "";
      filter_params.user_name = "";
      filter_params.phone = "";
      filter_params.rental_rate = "";
      filter_params.payment_source = "";
      filter_params.reference_code = "";
      filter_params.date_range = "";
      filter_params.lease_status = "";
      filter_params.allocation_status = "";
      filter_params.tenant_name = "";
      filter_params.propertyManager_name = "";
      filter_params.landlordId = "";
      filter_params.propertyManagerId = "";
      filter_params.property_name = "";
      filter_params.tenant_email = "";
      filter_visible.value = false;
    };
    const filterList = () => {
      filter_visible.value = false;
      props.filterCallBack(filter_params);
    };

    return {
      filter_paramsRef,
      tenants,
      properties,
      landlords,
      property_managers,
      filter_params,
      shortcuts,
      filter_visible,
      closeFilter,
      filterList
    };
  },

  created() {
    this.fetchTenantList();
    this.fetchProperties();
    this.fetchLandlords();
    this.fetchPropertyManager();
  },
  methods: {
    async fetchTenantList() {
      try {
        const response = await fetchLeasedManagerTenants();

        const tempTenants = response.data.map((tenant) => ({
          value: `${tenant.leases[0]?.leaseId}`,
          label: `${tenant.firstName  + " " + tenant.lastName  + " - " + tenant.leases[0]?.unit?.unitCode}`,
        }));

        tenants.value = tempTenants;
        console.log(tenants)
      } catch (error) {
        console.log(error);
      }
    },

    async fetchProperties() {
      try {
        const response = await fetchManagersProperties();

        const tempProperties = response.data.map((property) => ({
          value: `${property.propertyId}`,
          label: `${property.propertyName}`,
        }));

        properties.value = tempProperties;
        console.log(properties)
      } catch (error) {
        console.log(error);
      }
    },

    async fetchLandlords() {
      try {
        const response = await fetchManagerNotPaginatedLandlords();

        const tempLandlords = response.data.map((landlord) => ({
          value: `${landlord.id}`,
          label: `${landlord.name}`,
        }));

        landlords.value = tempLandlords;
        console.log(landlords)
      } catch (error) {
        console.log(error);
      }
    },

    async fetchPropertyManager() {
      try {
        const response = await fetchPropertyManagersData();

        const tempPropertyManagers = response.data.map((property_manager) => ({
          value: `${property_manager.id}`,
          label: `${property_manager.name}`,
        }));

        property_managers.value = tempPropertyManagers;
        console.log(property_managers)
      } catch (error) {
        console.log(error);
      }
    },
  },
}
</script>


<style scoped>
.custom-green-button{
  background-color: #5f924d;
  color: #fff;
}
</style>