<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <charts-table/>
      </div>
    </div>
  </div>
</template>

<script>
import ChartsTable from "../components/property-manager/ChartsTable.vue";

export default {
  name: "StatisticalCharts",
  components: {
    ChartsTable
  }
}
</script>


<style scoped>

</style>