<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <div class="card">
          <div class="pb-0 card-header text-start">
            <h4 class="font-weight-bolder">Settings</h4>
            <p class="mb-0">Update your business configuration below</p>
          </div>
          <div class="card-body">
            <el-form
              ref="businessFormRef"
              :model="businessForm"
              :rules="rules"
              label-width="auto"
              class="demo-ruleForm"
              size="large"
              status-icon
              :scroll-to-error="true"
              label-position="top"
              :hide-required-asterisk="true"
            >
              <div class="row">
                <div class="col-md-6">
                  <el-form-item label="Business Name" prop="business_name">
                    <el-input v-model="businessForm.business_name" />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    label="Business Email Address"
                    prop="business_email"
                  >
                    <el-input v-model="businessForm.business_email" />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    label="Business Address"
                    prop="business_address"
                  >
                    <el-input
                      v-model="businessForm.business_address"
                      placeholder="P.O Box. 0000, Nairobi,Kenya"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item label="Business Phone No" prop="business_phone">
                    <el-input v-model="businessForm.business_phone" />
                  </el-form-item>
                </div>
               

                <div class="col-md-6 mt-4">
                  <label prop="business_logo" class="el-form-item__label">Business Logo</label>
                  <el-upload
                      list-type="picture-card"
                      accept=".jpg,.png, .PNG, .JPEG, .jpeg"
                      action="https://web-api.m-reside.com/api/m-reside/v1/uploads"
                      :before-upload="handleBeforeUpload"
                      :on-success="handleUploadSuccess"
                      :on-change="onChange"
                      :file-list="businessForm.files"
                      :limit="1"
                      :on-error="handleUploadError"
                      >
                      <el-icon><Plus /></el-icon>

                      <template #file="{ file }">
                        <div>
                          <img
                              class="el-upload-list__item-thumbnail"
                              :src="file.url"
                               alt=""
                          />
                          <span class="el-upload-list__item-actions">
                          <span
                              class="el-upload-list__item-preview"
                              @click="handlePictureCardPreview(file)"
                          >
                            <el-icon><zoom-in /></el-icon>
                          </span>
                          <span
                              class="el-upload-list__item-delete"
                              @click="handleRemove(file)"
                          >
                            <el-icon><Delete /></el-icon>
                          </span>
                        </span>
                        </div>
                      </template>
                    </el-upload>
                </div>
              </div>
              <el-button
                type="success"
                class="px-5 d-block my-3 mx-auto"
                :loading="isLoading"
                @click="submitForm(businessFormRef)"
                v-if="hasPermission('update.business_settings')"
              >
                Update Settings
              </el-button>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog v-model="dialogVisible">
    <img w-full :src="dialogImageUrl" alt="Preview Image" />
  </el-dialog>
</template>
<script>
import { onMounted, reactive, ref } from "vue";
import { genFileId } from "element-plus";
import { Delete, Plus, ZoomIn } from "@element-plus/icons-vue";
import { ElNotification, ElMessage } from "element-plus";
import {fetchManagerDetails, updatePropertyManager} from "../../api/property-manager/manager";
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";

const upload = ref();
const businessFormRef = ref();
const isLoading = ref(false);
const phoneBindingProps = {
  mode: "international",
  styleClasses: "has-default",
  defaultCountry: "KE",
  onlyCountries: ["KE", "RW", "UG", "TZ", "ET", "BW"],
  inputOptions: {
    showDialCode: false,
    placeholder: "Mobile Number",
    name: "phone",
  },
};

const dialogImageUrl = ref("");
const dialogVisible = ref(false);
const propertyManagerId = localStorage.getItem("propertyManagerId");
const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
const userPermissions = ref(storedPermissions || [])

export default {
  components: {
    Delete,
    Plus,
    ZoomIn,
  },

  props: {
    modelValue: String, // Assuming you're passing the initial content as a prop
  },
  setup() {

    const businessForm = reactive({
      business_name: "",
      business_phone: "",
      business_email: "",
      business_address: "",
      invoice_footer_message: "",
      business_letter_head: "",
      business_logo: "",
      logo_file: {},
      files: [],

    });
    const checkMobileNumber = (event) => {
      if (event.valid) {
        businessForm.business_phone = event.number;
        phoneBindingProps.styleClasses = "has-success";
      } else {
        businessForm.business_phone = "";
        phoneBindingProps.styleClasses = "has-danger";
      }
    };
    const rules = reactive({
      business_name: [
        {
          required: true,
          message: "Please input the business name",
          trigger: ["blur", "change"],
        },
      ],
      business_phone: [
        {
          required: true,
          message: "Please input your business",
          trigger: ["blur", "change"],
        },
      ],
      business_email: [
        {
          required: true,
          message: "Please input your business email",
          trigger: ["blur", "change"],
        },
      ],
      business_address: [
        {
          required: true,
          message: "Please input your business address",
          trigger: ["blur", "change"],
        },
      ],
    });
    const fetchBusiness = async () => {
      isLoading.value = true;
      try {
        const response = await fetchManagerDetails(propertyManagerId);

        if (response.data?.id != null) {
          businessForm.business_name = response.data.tradingName;
          businessForm.business_logo = response.data.business_logo;
          businessForm.business_phone = response.data.phone;
          businessForm.business_email = response.data.email;
          businessForm.business_address = response.data.phy_add;
          businessForm.business_letter_head = response.data.letter_head;
          businessForm.invoice_footer_message = response.data.invoice_footer_message;
          businessForm.files.push({
            name: response.data.logo.name,
            url: response.data.logo.path,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const handleBeforeUpload = () => {
      //
    }

    const formData = new FormData();

    const handleUploadSuccess = (response, file) => {
      console.log('Upload success:', response, file);
      console.log(file)
      const fileContent = file.raw;

      formData.append("file", fileContent);
    }
    const handleUploadError = (err, file) => {
      console.error('Upload error:', err, file);
    }

    const selectedFile = ref('');

    const onChange = (file) => {
      businessForm.logo_file = file.raw;
    };

    const submitForm = async (formEl) => {
      console.log(selectedFile.value);
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;

          formData.append("file", businessForm.logo_file);
          formData.append("business_name", businessForm.business_name);
          formData.append("business_phone", businessForm.business_phone);
          formData.append("business_email", businessForm.business_email);
          formData.append("business_address", businessForm.business_address);

          updatePropertyManager(formData, propertyManagerId)
            .then((response) => {
              if (response.status == 200) {
                ElMessage({
                  message: response.data.message,
                  type: "success",
                });
              } else if (response.status == 422) {
                let error = Object.values(response.data.errors);

                console.log(error);
                error.forEach((err) =>
                  ElNotification({
                    title: "Error",
                    message: err[0],
                    type: "error",
                  })
                );
              }
            })
            .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
            )
            .finally(() => {
              isLoading.value = false;
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    const resetForm = (formEl) => {
      if (!formEl) return;
      formEl.resetFields();
    };
    onMounted(() => {
      fetchBusiness();
    });

    const handleRemove = () => {
      upload.value.clearFiles();
    };

    const handlePictureCardPreview = (file) => {
      dialogImageUrl.value = file.url;
      dialogVisible.value = true;
    };
    const handleExceed = (files) => {
      upload.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      console.log(file);
      upload.value.handleStart(file);
    };

    const business_letter_head_editor = ref(null);
    const invoice_footer_message_editor = ref(null);

    onMounted(() => {
      console.log('this',businessForm.business_email );
      const quill = new Quill(business_letter_head_editor.value, {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, false] }],
            ["bold", "italic", "underline", "strike", "link"],
            [{ list: "bullet" }, { list: "ordered" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],
            ["image"],
            ["clean"],
            ["page-break"],
            ["template"],
            [{'font': [ 'sans-serif', 'serif'] }],
            [{ 'color': [] }],
            ["copy", "cut", "paste"],
            ["preview", "fullscreen"],
            ["table"],

          ],
        },
        theme: "snow",
        fonts: {
          'customFont': 'Your Custom Font, sans-serif',
          'Helvetica': 'Helvetica, sans-serif',
          'Arial': 'Arial, sans-serif',
          'Times New Roman': '"Times New Roman", serif',
          'Comic Sans': '"Comic Sans MS", cursive',
          'Tahoma': 'Tahoma, sans-serif',
          'script': 'Script Font, cursive',
          'monospaced': 'Monospace Font, monospace',
          'display': 'Display Font, cursive',
        },
        formats: [
          "bold",
          "underline",
          "strike",
          "header",
          "italic",
          "link",
          "list",
          "indent",
          "align",
          "image",
          "page-break",
          "template",
          "font",
          "link",
          "image",
          "color",
          "preview",
          "fullscreen",
          "table",
        ],
        placeholder: "Type something in here",
      });

      quill.root.classList.add('ql-font-customFont');

      quill.getModule('toolbar').addHandler('color', (value) => {
        if (value === 'reset') {
          quill.format('color', false);
        } else {
          quill.format('color', value);
        }
      });
      


      fetchBusiness().then(content => {
        quill.setContents(content);

      });
    });
    onMounted(() => {
      const quill = new Quill(invoice_footer_message_editor.value, {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, false] }],
            ["bold", "italic", "underline", "strike", "link"],
            [{ list: "bullet" }, { list: "ordered" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],
            ["image"],
            ["clean"],
            ["page-break"],
            ["template"],
            [{ 'font': [ 'sans-serif', 'serif'] }],
            [{ 'color': [] }], // Add text color option
            ["copy", "cut", "paste"],
            ["preview", "fullscreen"],
            ["table"],
          ],
        },
        theme: "snow",
        fonts: {
          'customFont': 'Your Custom Font, sans-serif',
          'Helvetica': 'Helvetica, sans-serif',
          'Arial': 'Arial, sans-serif',
          'Times New Roman': '"Times New Roman", serif',
          'Comic Sans': '"Comic Sans MS", cursive',
          'Tahoma': 'Tahoma, sans-serif',
          'script': 'Script Font, cursive',
          'monospaced': 'Monospace Font, monospace',
          'display': 'Display Font, cursive',
        },
        formats: [
          "bold",
          "underline",
          "strike",
          "header",
          "italic",
          "link",
          "list",
          "indent",
          "align",
          "image",
          "page-break",
          "template",
          "font",
          "link",
          "image",
          "color",
          "preview",
          "fullscreen",
          "table",
        ],
        placeholder: "Type something in here!",
      });

      quill.root.classList.add('ql-font-customFont');
      quill.getModule('toolbar').addHandler('color', (value) => {
        if (value === 'reset') {
          quill.format('color', false);
        } else {
          quill.format('color', value);
        }
      });

      fetchBusiness().then(content => {
        quill.setContents(content);
      });
    });

    function hasPermission(slug) {
      return userPermissions.value.includes(slug);
    }

    return {
      onChange,
      handleUploadSuccess ,
      handleUploadError,
      handleBeforeUpload,
      hasPermission,
      selectedFile,
      upload,
      handleExceed,
      handleRemove,
      handlePictureCardPreview,
      dialogImageUrl,
      dialogVisible,
      isLoading,
      resetForm,
      submitForm,
      rules,
      businessForm,
      businessFormRef,
      phoneBindingProps,
      checkMobileNumber,
      business_letter_head_editor,
      invoice_footer_message_editor,
    };
  },
};
</script>
<style scoped>
.el-button .custom-loading .circular {
  margin-right: 6px;
  width: 18px;
  height: 18px;
  animation: loading-rotate 2s linear infinite;
}
</style>
