import {api} from "../index";

const admin_invoices_url = (root, filter, page) => {
    var url = root;

    if (filter.value !== undefined && filter.value.search_query)
        url =
            url +
            "&searchFields=invoice_number:like;number_of_units:like;commisioned_amount:like;&search=" +
            filter.value.search_query;

    if (page) url = url + "&page=" + page;

    url = url.replace("admin/invoices?", "admin/invoices?");

    return url;
};
export const fetchInvoices = async(filter, page) => {
    return await api
        .get(admin_invoices_url(`admin/invoices`, filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
};

export const getInvoice = async(filter, page) => {
    return await api
        .get(`admin/invoices`, filter, page)
        .then((response) => response.data)
        .catch((e) => e.response.data);
};
